
const { default: Welcome } = require("./components/Welcome");

function WelcomePage() {
  return (
    <>
    <Welcome />
    </>
  );
}

export default WelcomePage;
