import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import WelcomePage from "./Welcome";
import PreferencesPage from "./Preferences";
import SignupPage from "./Signup";
import { FormProvider } from "./context/FormContext";

function App() {
  return (
    <>
      <FormProvider>
        <Router>
          <Routes>
            <Route path="/preferences" element={<PreferencesPage />} />
            <Route path="/welcome" element={<WelcomePage />} />
            <Route path="/" element={<SignupPage />} />
          </Routes>
        </Router>
      </FormProvider>
    </>
  );
}

export default App;
