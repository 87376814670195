

const { default: Preferences } = require("./components/Preferences");

function PreferencesPage() {
  return (
    <>
    <Preferences />
    </>
  );
}

export default PreferencesPage;
