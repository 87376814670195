import Signup from "./components/Signup";


function SignupPage() {
  return (
    <>
    <Signup />
    </>
  );
}

export default SignupPage;
